import React from 'react';

const ResidentMoveOutConfirmationModal = (props) => {
	return (
		<div>
			{/* // Modal */}
			<div className={`modalContainer ${props.show ? 'show' : ''}`} onClick={props.onClose}>
				<div className='modalContent' onClick={(e) => e.stopPropagation()}>
					{/* stopPropagation : - //if you click inside the content, it will stop at .modal-content and the onClick in .modal will never be called */}

					<div className='modalHeader mx-3'>
						<h4 className='modalTitle'>{props.title}</h4>
					</div>
					<div className='modalTitle mx-3'>{props.bodyContent}</div>
					<div className='modalFooter'>
					
						<button
							className='btn btn-danger mx-2'
							style={{ borderRadius: '5px' }}
							onClick={props.onClose}
						>
							No
						</button>
						<button
							className='btn btn-light btn-sm mx-2'
							style={{ borderRadius: '5px' }}
							onClick={props.onSubmit}
							// onClick={props.onClose}
						>
							Yes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResidentMoveOutConfirmationModal;
