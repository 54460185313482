const initialState = {
	partnerPage: '',
	vehiclePage: '',
	unitPage: '',
	residentPage: '',
	guestPage: '',
}

const paginationReducer = (state = initialState, action) => {
	if (action.type === 'setPartnerPreviewPage') {
		return {
			...state,
			partnerPage: action.payload?.partnerPage,
		}
	}
	if (action.type === 'setVehiclePreviewPage') {
		return {
			...state,
			vehiclePage: action.payload?.vehiclePage,
		}
	}
	if (action.type === 'setResidentPreviewPage') {
		return {
			...state,
			residentPage: action.payload?.residentPage,
		}
	}
	if (action.type === 'setGuestPreviewPage') {
		return {
			...state,
			guestPage: action.payload?.guestPage,
		}
	}
	if (action.type === 'setUnitPreviewPage') {
		return {
			...state,
			unitPage: action.payload?.unitPage,
		}
	} else {
		return state
	}
}

export default paginationReducer
