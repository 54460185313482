import React, { useEffect, useState } from 'react';
import * as api from '../../../api';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { setAlert, setLoading } from '../../../globalState/action-creators';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../../components/bootstrap/Button';

const DetailUnit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [unitData, setUnitData] = useState({});

	const token = sessionStorage.getItem('token');
	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}

		fetchUnit(id);
		// eslint-disable-next-line
	}, []);

	const fetchUnit = async (id) => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getUnitById(id);
			if (data?.success) {
				setUnitData(data?.data);
			} else {
				dispatch(setAlert(data?.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	return (
		<PageWrapper title={'Unit Detail'}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader style={{ borderBottom: '1px solid gray' }}>
								<CardLabel icon='emoji_transportation' iconColor='primary'>
									<CardTitle tag='h4' className='h5'>
										Unit Details
									</CardTitle>
								</CardLabel>
								<Button onClick={() => navigate('/Unit')}>
									<i
										className='bi bi-x '
										style={{
											fontSize: '26px',
											color: 'rgb(59, 43, 75)',
											fontWeight: '700',
										  }}
									></i>
								</Button>
							</CardHeader>
							<CardBody className='table-responsive'>
								{/* <div className='containerDetailView'> */}
								<div className='unitSubContainerDetailView text-start'>
									<div className='cardDetailView'>
										<p>
											<strong>Unit Number</strong>
										</p>
										<p>{unitData?.unitNumber ? unitData?.unitNumber : '-'}</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Resident Vehicles Allowed</strong>
										</p>
										<p>
											{unitData?.residentAllowedVehicles
												? unitData?.residentAllowedVehicles
												: '-'}
										</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Maximum Guest Allowed Vehicles</strong>
										</p>
										<p>
											{unitData?.guestAllowedVehicles
												? unitData?.guestAllowedVehicles
												: '-'}
										</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Maximum Guest Access Duration</strong>
										</p>
										<p>
											{unitData?.guestAccessDuration
												? `${unitData?.guestAccessDuration} ${unitData?.guestAccessDurationType}`
												: '-'}
										</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Zones</strong>
										</p>
										<p>{unitData?.zone ? unitData?.zone : '-'}</p>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DetailUnit;
