import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as api from '../api';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import { useDispatch } from 'react-redux';
import { setAlert } from '../globalState/action-creators';

const Search = () => {
	const Actions = ['Info', 'Edit'];

	const tz = moment.tz.guess();
	const refSearchInput = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [searchData, setSearchData] = useState({
		total: 0,
		partner: [],
		resident: [],
		guest: [],
		vehicle: [],
		unit: [],
	});

	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setSearchModalStatus(true);
		},
	});

	const Search = async () => {
		try {
			const {
				data: { success, data, total, message },
			} = await api.universalSearch(formik?.values?.searchInput);

			if (success) {
				setSearchData({
					total: total || 0,
					partner: _.get(data, 'partner', []),
					resident: _.get(data, 'resident', []),
					guest: _.get(data, 'guest', []),
					vehicle: _.get(data, 'vehicle', []),
					unit: _.get(data, 'unit', []),
				});
			} else {
				dispatch(setAlert(message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
			console.log(error);
		}
	};

	useEffect(() => {
		if (formik.values.searchInput) {
			setSearchModalStatus(true);
			refSearchInput?.current?.focus();
			Search();
		}
		return () => {
			setSearchModalStatus(false);
			formik.values.searchInput = '';
		};
		//eslint-disable-next-line
	}, [formik.values.searchInput]);

	return (
		<>
			<div className='d-flex' data-tour='search'>
				<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Search...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
				/>
			</div>
			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				data-tour='search-modal'
			>
				<ModalHeader setIsOpen={setSearchModalStatus}>
					<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						ref={refSearchInput}
						name='searchInput'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search...'
						autoComplete='off'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</ModalHeader>
				<ModalBody>
					<table className='table table-hover table-modern caption-top mb-0'>
						<caption>Results: {searchData?.total}</caption>
						<tbody>
							{searchData && searchData?.total > 0 ? (
								<>
									{searchData?.partner && searchData?.partner?.length > 0 && (
										<strong>Partners</strong>
									)}
									{searchData?.partner &&
										searchData?.partner?.length > 0 &&
										searchData?.partner?.map((item, index) => {
											return (
												<tr
													key={index}
													className='cursor-pointer'
												
												>
													<td className='vehicle-flex search-hover'>
														<div>
															<Icon
																icon='Person'
																size='lg'
																className='me-2'
																color='primary'
															/>
															{item?.partnerName +
																` (${item?.businessName})`}
														</div>
														<div>
															{Actions &&
																Actions?.length > 0 &&
																Actions.map((e) => {
																	return (
																		<Button
																			key={e}
																			style={{
																				width: '36px',
																				margin: '2px',
																			}}
																			color='primary'
																			isLight
																			icon={
																				e !== 'Info'
																					? e
																					: 'Eye'
																			}
																			onClick={() => {
																				if (e === 'Info') {
																					navigate(
																						`/partners/detail/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				} else if (
																					e === 'Edit'
																				) {
																					navigate(
																						`/partners/edit/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				}
																			}}
																		/>
																	);
																})}
														</div>
													</td>
												</tr>
											);
										})}

									{searchData?.resident && searchData?.resident?.length > 0 && (
										<strong>Residents</strong>
									)}
									{searchData?.resident &&
										searchData?.resident?.length > 0 &&
										searchData?.resident?.map((item, index) => {
											return (
												<tr
													key={index}
													className='cursor-pointer'
													
												>
													<td className='vehicle-flex search-hover'>
														<div>
															<Icon
																icon='RecentActors'
																size='lg'
																className='me-2'
																color='primary'
															/>
															{item?.residentName +
																` (${item?.licensePlate})`}
														</div>
														<div>
															{Actions &&
																Actions?.length > 0 &&
																Actions.map((e) => {
																	return (
																		<Button
																			key={e}
																			style={{
																				width: '36px',
																				margin: '2px',
																			}}
																			color='primary'
																			isLight
																			icon={
																				e !== 'Info'
																					? e
																					: 'Eye'
																			}
																			onClick={() => {
																				if (e === 'Info') {
																					navigate(
																						`/residents_information/detail/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				} else if (
																					e === 'Edit'
																				) {
																					navigate(
																						`/residents_information/edit/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				}
																			}}
																		/>
																	);
																})}
														</div>
													</td>
												</tr>
											);
										})}
									{searchData?.guest && searchData?.guest?.length > 0 && (
										<strong>Guests</strong>
									)}
									{searchData?.guest &&
										searchData?.guest?.length > 0 &&
										searchData?.guest?.map((item, index) => {
											return (
												<tr
													key={index}
													className='cursor-pointer'
												
												>
													<td className='vehicle-flex search-hover'>
														<div>
															<Icon
																icon='Person'
																size='lg'
																className='me-2'
																color='primary'
															/>
															{item?.guestName +
																` (${item?.licensePlate})`}
														</div>
														<div>
															{Actions &&
																Actions?.length > 0 &&
																Actions.map((e) => {
																	return (
																		<Button
																			key={e}
																			style={{
																				width: '36px',
																				margin: '2px',
																			}}
																			color='primary'
																			isLight
																			icon={
																				e !== 'Info'
																					? e
																					: 'Eye'
																			}
																			onClick={() => {
																				if (e === 'Info') {
																					navigate(
																						`/guests/detail/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				} else if (
																					e === 'Edit'
																				) {
																					navigate(
																						`/guests/edit/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				}
																			}}
																		/>
																	);
																})}
														</div>
													</td>
												</tr>
											);
										})}

									{searchData?.vehicle && searchData?.vehicle?.length > 0 && (
										<strong>Vehicles</strong>
									)}
									{searchData?.vehicle &&
										searchData?.vehicle?.length > 0 &&
										searchData?.vehicle?.map((item, index) => {
											return (
												<tr key={index} className='cursor-pointer '>
													<td className='vehicle-flex search-hover'>
														<div>
															<Icon
																icon='Vehicle'
																size='lg'
																className='me-2'
																color='primary'
															/>
															{item?.name +
																` (${item?.licensePlate})`}
														</div>
														<div className='vehicle_btn'>
															{Actions &&
																Actions?.length > 0 &&
																Actions.map((e) => {
																	return (
																		<Button
																			key={e}
																			style={{
																				width: '36px',
																				margin: '2px',
																			}}
																			color='primary'
																			isLight
																			icon={
																				e !== 'Info'
																					? e
																					: 'Eye'
																			}
																			onClick={() => {
																				if (e === 'Info') {
																					navigate(
																						`/vehicles/detail/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				} else if (
																					e === 'Edit'
																				) {
																					navigate(
																						`/vehicles/edit/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				}
																			}}
																		/>
																	);
																})}
														</div>
													</td>
												</tr>
											);
										})}

									{searchData?.unit && searchData?.unit?.length > 0 && (
										<strong>Unit</strong>
									)}
									{searchData?.unit &&
										searchData?.unit?.length > 0 &&
										searchData?.unit?.map((item, index) => {
											return (
												<tr
													key={index}
													className='cursor-pointer '
												
												>
													<td className='vehicle-flex search-hover'>
														<div>
															<Icon
																icon='emoji_transportation'
																size='lg'
																className='me-2'
																color='primary'
															/>
															{item?.zone
																? item?.zone +
																  ` (${item?.unitNumber})`
																: ` (${item?.unitNumber})`}
														</div>
														<div>
															{Actions &&
																Actions?.length > 0 &&
																Actions.map((e) => {
																	return (
																		<Button
																			key={e}
																			style={{
																				width: '36px',
																				margin: '2px',
																			}}
																			color='primary'
																			isLight
																			icon={
																				e !== 'Info'
																					? e
																					: 'Eye'
																			}
																			onClick={() => {
																				if (e === 'Info') {
																					navigate(
																						`/Unit/detail/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				} else if (
																					e === 'Edit'
																				) {
																					navigate(
																						`/Unit/edit/${item?._id}`,
																					);
																					setSearchModalStatus(
																						false,
																					);
																					formik.values.searchInput =
																						'';
																				}
																			}}
																		/>
																	);
																})}
														</div>
													</td>
												</tr>
											);
										})}
								</>
							) : (
								<tr className='table-active'>
									<td>No result found for query "{formik.values.searchInput}"</td>
								</tr>
							)}
						</tbody>
					</table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Search;
