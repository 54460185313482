import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import FormGroup from '../bootstrap/forms/FormGroup';
import Button from '../bootstrap/Button';
import DatePicker from 'react-date-picker';
import _ from 'lodash';

const MoveOutResidentModal = ({
	setConfirmationModalContent,
	setModalConfirmationTitle,
	isOpen,
	setIsOpen,
	setIsConfirmationModalOpen,
	handleMoveOutModalClose,
	moveOutFormData,
	setMoveOutFormData,
}) => {
	const [moveOutDateError, setMoveOutDateError] = useState(false);
	const [isCheckBoxError, setIsCheckBoxError] = useState(false);

	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setMoveOutFormData((prevState) => ({
			...prevState,
			isMoveOutDate: name === 'isMoveOutDate' ? checked : false,
			isMoveOutNow: name === 'isMoveOutNow' ? checked : false,
			MoveOutDate: name === 'isMoveOutNow' && '',
		}));

		setIsCheckBoxError(false);
		setMoveOutDateError(false);
		if (name === 'isMoveOutDate' && checked) {
			setModalConfirmationTitle(
				'Are you sure you want to set move out date for this resident?',
			);
			setConfirmationModalContent(
				"All Resident and Guest passes attached to Resident's unit will expire and archive on Move out date.",
			);
		}
		if (name === 'isMoveOutNow' && checked) {
			setModalConfirmationTitle('Are you sure you want to move this resident out?');
			setConfirmationModalContent(
				"Clicking yes will expire and archive all Resident and Guest passes attached to Resident's unit.",
			);
		}
	};

	const handleSubmit = () => {
		let error = false;
		if (!moveOutFormData?.isMoveOutDate && !moveOutFormData?.isMoveOutNow) {
			setIsCheckBoxError(true);
			error = true;
		}
		if (moveOutFormData?.isMoveOutDate) {
			if (_.get(moveOutFormData, 'MoveOutDate', '')?.length <= 0) {
				error = true;
				setMoveOutDateError(true);
			} else if (_.get(moveOutFormData, 'MoveOutDate', '')?.length > 0) {
				const moveInDate = new Date(moveOutFormData.MoveInDate);
				const yesterday = new Date();
				yesterday.setDate(yesterday.getDate() - 1);
				if (moveInDate < yesterday) {
					error = true;
					setMoveOutDateError(true);
				}
			}
		}

		if (!error) {
			setIsConfirmationModalOpen(true);
			handleMoveOutModalClose();
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} isCentered={true} setIsOpen={setIsOpen} size='sm'>
				<ModalHeader setIsOpen={setIsOpen} className='p-4 d-flex align-items-start'>
					<ModalTitle>Would you like to set a move out date or move out now?</ModalTitle>
					<Button className='p-0' onClick={() => handleMoveOutModalClose()}>
						<i
							className='bi bi-x '
							style={{
								fontSize: '22px',
								color: 'rgb(59, 43, 75)',
								fontWeight: '700',
							}}
						></i>
					</Button>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup>
							<div className='d-flex w-100 align-items-center'>
								<input
									type='checkbox'
									className='partnerCheckbox'
									name='isMoveOutDate'
									checked={moveOutFormData.isMoveOutDate}
									onChange={handleCheckboxChange}
								/>
								<span className='ps-2'>Set Move Out Date</span>
							</div>
						</FormGroup>

						{moveOutFormData.isMoveOutDate && (
							<FormGroup>
								<div>
									<div className='label'>
										<p className='mx-3 lable_spacing'>
											Enter Move Out Date{' '}
											<span className='text-danger fw-bold'>*</span>
										</p>
									</div>
									<div className='data'>
										<DatePicker
											className='form-control addVehicleSelect input_feild'
											clearIcon={null}
											name='MoveOutDate'
											onChange={(e) => {
												const formattedDate = e
													? moment(e).format('MM/DD/yyyy')
													: '';
												setMoveOutFormData((prevState) => ({
													...prevState,
													MoveOutDate: formattedDate,
												}));
												setMoveOutDateError(false);
											}}
											format='MM/dd/yyyy'
											value={
												moveOutFormData.MoveOutDate
													? new Date(moveOutFormData.MoveOutDate)
													: ''
											}
											minDate={moveOutFormData?.minDate}
										/>
										<span className='text-danger danger-msg'>
											{moveOutDateError ? 'Move Out date required!' : ''}
										</span>
									</div>
								</div>
							</FormGroup>
						)}

						<FormGroup>
							<div className='d-flex w-100 align-items-center'>
								<input
									type='checkbox'
									className='partnerCheckbox'
									name='isMoveOutNow'
									checked={moveOutFormData.isMoveOutNow}
									onChange={handleCheckboxChange}
								/>
								<span className='ps-2'>Move Out Now</span>
							</div>
						</FormGroup>

						{isCheckBoxError && (
							<span className='text-danger danger-msg'>Kindly select an option!</span>
						)}
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button color='info' onClick={handleSubmit}>
						Submit
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

MoveOutResidentModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default MoveOutResidentModal;
