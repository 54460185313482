import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

API.interceptors.request.use((req) => {
	if (sessionStorage.getItem('token')) {
		req.headers.authorization = `Bearer ${sessionStorage.getItem('token')}`;
	}

	return req;
});

/**
 * @section - Vehicles
 * @token - required
 */

export const getVehiclesByPartner = (data, query) =>
	API.get(
		`/api/vehicle/vehiclesByPartner/${data.partnerId}${query !== undefined ? '?' + query : ''}`,
	);

/**
 * @section Rates
 * @token Required
 */

export const getRates = () => API.get('/api/rates/get');
export const getRateById = (id) => API.get(`/api/rates/${id}`);
export const addRate = (formData) => API.post('/api/rates/create', formData);
export const editRate = (id, formData) => API.put(`/api/rates/update/${id}`, formData);
export const deleteRates = (id) => API.delete(`/api/rates/delete/${id}`);

export const getRatesByPartner = (data) => API.get(`/api/rates/retesByPartner/${data.partnerId}`);

/**
 *
 * @section Change rates
 */
export const getDynamicRatesByPartner = (id) => API.get(`/api/partners_rates/${id}`);
export const editDynaminRatesByPartner = (id, formData) =>
	API.put(`/api/partners_rates/${id}`, formData);

/**
 * @section Reservation
 * @token Required
 */

export const getPartnersReservations = (id, query) =>
	API.get(`/api/partner/reservations/${id}${query !== undefined ? '?' + query : ''}`);
export const getAllReservations = (query) =>
	API.get(`/api/reservations${query !== undefined ? '?' + query : ''}`);
export const getAllSucceededPartnersReservations = (query, formData) =>
	API.post(
		`/api/partner/reservations/revenue${query !== undefined ? '?' + query : ''}`,
		formData,
	);
export const getAllSucceededReservations = (query, formData) =>
	API.post(`/api/reservations/revenue${query !== undefined ? '?' + query : ''}`, formData);
export const addReservation = (formData) => API.post(`/api/reservation/create`, formData);
export const getReservationById = (id) => API.get(`/api/reservation/${id}`);
export const getRevenueById = (id) => API.get(`/api/revenue/${id}`);
export const updateReservation = (id, formData) =>
	API.put(`/api/reservation/update/${id}`, formData);
export const addNotesInReservation = (id, formData) =>
	API.patch(`/api/reservation/notes/${id}`, formData);
export const deleteReservation = (id) => API.delete(`/api/reservation/${id}`);

/**
 * @Section AVOID RESERVATION
 */
export const avoidReservation = (id) => API.put(`api/v2/avoid?reservationId=${id}`);
export const markCompleteReservation = (id) => API.put(`api/v2/reservation/complete/${id}`);

/**
 * @Section REFUND BOOKING AMOUNT
 */
export const refund = (id, amount, isAdditionalCharge, formData) =>
	API.post(
		`/api/v2/refund?reservationId=${id}&amount=${amount}&isAdditionalCharge=${isAdditionalCharge}`,
		formData,
	);

/**
 * @Post
 * @section - authentication
 * @param {
 * email: string
 * password: string
 * } formData
 *
 */
export const login = (formData) => API.post('/api/user/login', formData);

/**
 * @Post
 * @section - userSwitch
 * @param {
 * email: string
 * } formData
 *
 */
export const switchUser = (formData) => API.post('/api/user/token', formData);

/**
 * @section - Dashboard
 * @token - required
 */

export const dashboard = () => API.get('/api/dashboard/getData');

/**
 * @section -  Vehicles
 * @token - required
 */

export const getVehicles = (page, size) => API.get(`api/vehicle/get?page=${page}&size=${size}`);
export const getVehicleById = (id) => API.get(`/api/vehicleById/${id}`);
export const createVehicle = (formData) => API.post(`/api/vehicle/create`, formData);
export const updateVehicle = (formData) => API.put(`/api/vehicle/update`, formData);
export const deleteVehicle = (id) => API.delete(`/api/vehicle/${id}`);
export const searchNames = (query) => API.get(`/api/names/search?searchQuery=${query}`);

/**
 * @section - Residents
 * @token - required
 */

export const addResidents = (formData) => API.post(`/api/resident/create`, formData);
export const updateResident = (formData) => API.put(`/api/resident/update`, formData);
export const getResidentById = (id) => API.get(`/api/residentById/${id}`);
export const getResidents = (page, size, status) =>
	API.get(`/api/resident/get?status=${status}&page=${page}&size=${size}`);
export const deleteResident = (id) => API.delete(`/api/resident/${id}`);
export const getAllUnitNumber = () => API.get(`/api/resident/AllunitsNumber`);
export const getAllPartnerNames = () => API.get(`/api/resident/AllPartners`);
export const moveOutResident = (formData) => API.patch(`/api/resident/moveOut`, formData);

/**
 * @section - Guest
 * @token - required
 */

export const addGuest = (formData) => API.post(`/api/guest/create`, formData);
export const updateGuest = (formData) => API.put(`/api/guest/update`, formData);
export const getGuest = (page, size) => API.get(`/api/guest/get?page=${page}&size=${size}`);
export const getGuestById = (id) => API.get(`/api/guestById/${id}`);
export const deleteGuest = (id) => API.delete(`/api/guest/${id}`);

/**
 * @section - Units
 * @token - required
 */

export const addUnit = (formData) => API.post(`/api/unit/create`, formData);
export const updateUnit = (formData) => API.put(`/api/unit/update`, formData);
export const getUnitById = (id) => API.get(`/api/unitById/${id}`);
export const getUnits = (page, size) => API.get(`/api/unit/get?page=${page}&size=${size}`);
export const deleteUnit = (id) => API.delete(`/api/unit/${id}`);

/**
 * @section - VehiclesByResidents
 * @token - required
 */

export const getAllUserNames = () => API.get(`/api/user/getAllUserName`);

/**
 * @section - Profile
 * @token - required
 */

export const getProfileData = () => API.get(`/api/user/profile`);
/**
 * @section - Partners
 * @token - required
 */

export const addPartner = (formData) => API.post(`/api/partner/create`, formData);
export const updatePartner = (formData) => API.put(`/api/partner/update`, formData);
export const getPartnerById = (id) => API.get(`/api/partnerById/${id}`);
export const getPartners = (page, size) => API.get(`/api/partner/get?page=${page}&size=${size}`);
export const deletePartner = (id) => API.delete(`/api/partner/${id}`);

/**
 * @section Universal Search
 * @token Required
 */

export const universalSearch = (query) => API.get(`/api/searchData?q=${query}`);
