import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as api from '../../../../api';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../../globalState/action-creators';
import Button from '../../../../components/bootstrap/Button';
import { USER_ROLE } from '../../../../constants';

const DetailResidenters = () => {
	const user = useSelector((state) => state?.user);
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchResidenter();
		// eslint-disable-next-line
	}, []);

	const [residenterData, setResidenterData] = useState({});

	const fetchResidenter = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getResidentById(id);
			if (data?.success) {
				setResidenterData(data?.data);
			} else {
				dispatch(setAlert(data.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	return (
		<PageWrapper title={'Resident Detail'}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6"'>
						<Card>
							<CardHeader>
								<CardLabel icon='RecentActors' iconColor='primary'>
									<CardTitle tag='h4' className='h5'>
										Resident Profile
									</CardTitle>
								</CardLabel>
								<Button onClick={() => navigate(-1)}>
									<i
										className='bi bi-x '
										style={{
											fontSize: '26px',
											color: 'rgb(59, 43, 75)',
											fontWeight: '700',
										}}
									></i>
								</Button>
							</CardHeader>
							<CardBody className='table-responsive'>
								<div className='unitSubContainerDetailView text-start'>
									<div className='cardDetailView'>
										<p>
											<strong>Name</strong>
										</p>
										<p>
											{residenterData?.residentName
												? residenterData?.residentName
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Phone Number</strong>
										</p>
										<p>
											{residenterData?.phoneNumber
												? residenterData?.phoneNumber
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Email</strong>
										</p>
										<p>{residenterData?.email ? residenterData?.email : '-'}</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Resident Type</strong>
										</p>
										<p>
											{residenterData?.residentType
												? residenterData?.residentType
												: '-'}
										</p>
									</div>

									{user?.role === USER_ROLE.SUPER_ADMIN && (
										<div className='cardDetailView'>
											<p>
												<strong>Partner Name </strong>
											</p>
											<p>
												{residenterData?.partnerName
													? residenterData?.partnerName
													: '-'}
											</p>
										</div>
									)}

									<div className='cardDetailView'>
										<p>
											<strong>License Plate</strong>
										</p>
										<p>
											{residenterData?.licensePlate
												? residenterData?.licensePlate
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Unit</strong>
										</p>
										<p>{residenterData?.units ? residenterData?.units : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Make</strong>
										</p>
										<p>{residenterData?.make ? residenterData?.make : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Model</strong>
										</p>
										<p>{residenterData?.model ? residenterData?.model : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Move in Date</strong>
										</p>
										<p>
											{residenterData?.MoveInDate
												? moment(residenterData?.MoveInDate).format(
														'MM/DD/YYYY',
												  )
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Move out Date</strong>
										</p>
										<p>
											{residenterData?.MoveOutDate
												? moment(residenterData?.MoveOutDate).format(
														'MM/DD/YYYY',
												  )
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Lease End Date</strong>
										</p>
										<p>
											{residenterData?.leaseEndDate
												? moment(residenterData?.leaseEndDate).format(
														'MM/DD/YYYY',
												  )
												: '-'}
										</p>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DetailResidenters;
