import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import * as api from '../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card'
import { setAlert, setLoading } from '../../../globalState/action-creators'
import Page from '../../../layout/Page/Page'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { USER_ROLE } from '../../../constants'
import Button from '../../../components/bootstrap/Button';


const DetailHoldVehicle = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector((state) => state?.user)

	const [vehicle, setVehicle] = useState({})

	const token = sessionStorage.getItem('token')
	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true })
			return;
		}

		fetchVehicle(id)
		// eslint-disable-next-line
	}, [])

	const fetchVehicle = async (id) => {
		dispatch(setLoading(true))
		try {
			const { data } = await api.getVehicleById(id)
			if (data?.success) {
				setVehicle(data?.data)
			} else {
				dispatch(setAlert(data?.message, 'Error'))
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'))
		}
		dispatch(setLoading(false))
	}

	return (
		<PageWrapper title={'Detail'}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader style={{ borderBottom: '1px solid gray' }}>
								<CardLabel icon='Vehicle' iconColor='primary'>
									<CardTitle tag='h4' className='h5'>
										Vehicle Details
									</CardTitle>
								</CardLabel>
								<Button onClick={() => navigate('/vehicles')}>
									<i
										className='bi bi-x '
										style={{
											fontSize: '26px',
											color: 'rgb(59, 43, 75)',
											fontWeight: '700',
										  }}
									></i>
								</Button>
							</CardHeader>
							<CardBody className='table-responsive'>
								{/* <div className='containerDetailView'> */}
								<div className='subContainerDetailView text-start justify-content-start'>

									<div className='cardDetailView'>
										<p>
											<strong>Vehicle Type</strong>
										</p>
										<p>{vehicle?.vehicleType ? vehicle?.vehicleType : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Name</strong>
										</p>
										<p>{vehicle?.name ? vehicle?.name : '-'}</p>
									</div>

									{/*                
                  <div className='subContainerDetailView text-start'> */}
									<div className='cardDetailView'>
										<p>
											<strong>License Plate</strong>
										</p>
										<p>{vehicle?.licensePlate ? vehicle?.licensePlate : '-'}</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Make</strong>
										</p>
										<p>{vehicle?.make ? vehicle?.make : '-'}</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Model</strong>
										</p>
										<p>{vehicle?.model ? vehicle?.model : '-'}</p>
									</div>


							
								</div>
								{/* </div> */}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	)
}

export default DetailHoldVehicle
