const initialState = {
	email: '',
	role: '',
	id: '',
	isAccessToRates: false,
	isAccessToRevenue: false,
	isAccessToReservation: false,
};

const userReducer = (state = initialState, action) => {
	if (action.type === 'setAccount') {
		return {
			...state,
			email: action.payload?.email,
			role: action.payload?.role,
			isAccessToRates: action.payload?.isAccessToRates,
			isAccessToReservation: action.payload?.isAccessToReservation,
			isAccessToRevenue: action.payload?.isAccessToRevenue,
			id: action.payload?._id,
		};
	} else {
		return state;
	}
};

export default userReducer;
