import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import { USER_ROLE } from '../../constants';
// import User from '../User/User';
import {
	componentsMenu,
	dashboardMenu,
	usePartnerPages,
	parkerPages,
	// demoPages,
	layoutMenu,
	resourcePages,
} from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
// import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import { setAccount, setLoading } from '../../globalState/action-creators';
import { useNavigate } from 'react-router-dom';

const Aside = () => {
	const partnerPages = usePartnerPages();
	const user = useSelector((state) => state?.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { darkModeStatus } = useDarkMode();
	const impersonateBy = sessionStorage.getItem('admintoken');
	const impersonatebyPartner = sessionStorage.getItem('partnertoken');

	const handleBackUser = () => {
		dispatch(setLoading(true));
		if (impersonateBy && !impersonatebyPartner) {
			sessionStorage.setItem('token', impersonateBy);
			sessionStorage.removeItem('admintoken');
			const userData = JSON.parse(sessionStorage.getItem('userData'));
			sessionStorage.removeItem('newAdminData');
			dispatch(
				setAccount({
					email: userData?.email,
					role: userData?.role,
					_id: userData?._id,
					isAccessToRates: userData?.isAccessToRates,
					isAccessToRevenue: userData?.isAccessToRevenue,
					isAccessToReservation: userData?.isAccessToReservation,
				}),
			);
			navigate('/');
			dispatch(setLoading(false));
		} else if (impersonateBy && impersonatebyPartner) {
			sessionStorage.setItem('token', impersonatebyPartner);
			sessionStorage.removeItem('partnertoken');
			const userData = JSON.parse(sessionStorage.getItem('newAdminData'));
			sessionStorage.removeItem('newPartnerData');
			dispatch(
				setAccount({
					email: userData?.email,
					role: userData?.role,
					_id: userData?._id,
					isAccessToRates: userData?.isAccessToRates,
					isAccessToReservation: userData?.isAccessToReservation,
					isAccessToRevenue: userData?.isAccessToRevenue,
				}),
			);
			navigate('/');
			dispatch(setLoading(false));
		} else if (!impersonateBy && impersonatebyPartner) {
			sessionStorage.setItem('token', impersonatebyPartner);
			sessionStorage.removeItem('partnertoken');
			const userData = JSON.parse(sessionStorage.getItem('userData'));
			sessionStorage.removeItem('newPartnerData');
			dispatch(
				setAccount({
					email: userData?.email,
					role: userData?.role,
					_id: userData?._id,
					isAccessToRates: userData?.isAccessToRates,
					isAccessToReservation: userData?.isAccessToReservation,
					isAccessToRevenue: userData?.isAccessToRevenue,
				}),
			);
			navigate('/');
			dispatch(setLoading(false));
		}
	};

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}
			>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					{user?.role && user?.role?.length > 0 && user?.role === USER_ROLE?.PARTNER && (
						<Navigation menu={partnerPages} id='aside-dashboard' />
					)}

					{user?.role && user?.role?.length > 0 && user?.role === USER_ROLE?.PARKER && (
						<Navigation menu={parkerPages} id='aside-dashboard' />
					)}
					{user?.role && user?.role?.length > 0 && user?.role === USER_ROLE?.ADMIN && (
						<Navigation menu={dashboardMenu} id='aside-dashboard' />
					)}
					{user?.role &&
						user?.role?.length > 0 &&
						user?.role === USER_ROLE?.SUPER_ADMIN && (
							<Navigation menu={dashboardMenu} id='aside-dashboard' />
						)}
					{!doc && (
						<>
							{/* <NavigationLine /> */}
							{/* <Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine /> */}
							<Navigation menu={resourcePages} id='aside-demo-pages' />
							{/* <NavigationLine /> */}
							<Navigation menu={layoutMenu} id='aside-menu' />
						</>
					)}

					{doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p
									className={classNames('h4', {
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									})}
								>
									{'Everything is ready!'}
								</p>
								{/* <Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button> */}
							</CardBody>
						</Card>
					)}

					{(impersonateBy || impersonatebyPartner) && (
						<div
							className='brand mt-auto mb-3'
							style={{ justifyContent: 'center', height: 'auto' }}
						>
							<div className='brand-logo'>
								<div className='brand-title '>
									{' '}
									<Button
										color='dark'
										isLight
										style={{ width: '90%' }}
										onClick={handleBackUser}
									>
										{impersonateBy && !impersonatebyPartner
											? 'BACK TO SUPER ADMIN'
											: 'BACK TO ADMIN'}
									</Button>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='aside-foot'></div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
